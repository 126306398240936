import React, { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useHistory } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import SnapPixel from "react-snapchat-pixel";
import TiktokPixel from "tiktok-pixel";
import { toast } from "react-toastify";
import {
  AreaContext,
  CartContext,
  InterNationalContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../App";
import { addCartTag } from "../cartaddtag";
import Spinner from "../SVGs/Spinner";
import {
  addToCartApi,
  removeCartItem,
  updateCartQauntity,
} from "./APIS/productApi";
import { triggerAddToCart } from "./APIS/facebookApi";
import BrokenImage from "./Common/BrokenImage";

function ToggleCategoryProduct({ item }) {
  const { cart, setCart } = useContext(CartContext);
  const { language } = useContext(LanguageContext);
  const { areaDetails } = useContext(AreaContext);
  const history = useHistory();
  const vendorSlug = useContext(VendorSlugContext);
  const details = useContext(VendorContext);
  const [inCart, setInCart] = useState(0);
  const [spinLoader, setSpinLoader] = useState(false);
  const { internationalDelivery } = useContext(InterNationalContext);

  const notify = (message, message_ar, language) =>
    toast.success(language == "ltr" ? message : message_ar);

  const onAddToCartClick = async (event, n) => {
    event.preventDefault();

    const temp = cart?.cartItems?.filter((k, i) => item?.id == k?.product_id);
    if (item?.add_ons_count?.cnt != 0 || item?.variation_count?.cnt != 0) {
      history.push(`/product=${item?.product_slug}`);
    } else if (n == -1 && inCart == 1) {
      setSpinLoader(true);
      const response = await removeCartItem({
        vendorSlug: vendorSlug,
        vendors_id: details?.vendor?.vendors_id,
        area_id: areaDetails?.area_id,
        user_string: localStorage.getItem("userID"),
        item_id: temp[0]?.item_id,
      });
      if (response?.status) {
        if (response.data.cartCount == 0) {
          setSpinLoader(false);
          setCart((cart) => { });
        } else {
          setSpinLoader(false);
          setCart(response.data);
        }
      }
    } else {
      if (inCart + n <= Number(item?.quantity)) {
        if (temp?.length === 0 || !temp?.length) {
          const response = await addToCartApi({
            vendorSlug: vendorSlug,
            vendors_id: details?.vendor?.vendors_id,
            area_id: areaDetails?.area_id,
            itemId: item?.id,
            user_string: localStorage.getItem("userID"),
            quantity: inCart + n,
            branch_id: 87,
            add_on_ids: [],
          });
          if (response?.status) {
            localStorage.setItem("cartTime", new Date());

            if (response.status == false) {
              notify(response.message, response.message_ar, language);
            }
            if (details?.vendor?.fb_pixel_code != "") {
              ReactPixel.track("AddToCart", {
                content_name: item?.product_name,
                content_category: item?.category_name,
                content_ids: [item?.id],
                content_type: "product",
                value: item?.product_price,
                currency: "KWD",
              });

              if (vendorSlug == "butters") {
                triggerAddToCart({
                  fb_pixel_code: "546180060531909",
                  fb_access_token:
                    "EAAVDp1efPkMBOZBE2DPWrA7he9iJFn9EZBUpd4k3cRjpApcbNMLJgbdmelpI1uApMyxEYoorwBD5ZBDGPL5NWMxXGrKpoAHHxG9NtBMrppMm8YHLRmFgiYVL37nu7PUaO3WPfz4U4K75jIH7eErUZCSRAeJJyQpc88THHEBQGMozZBM9894dBoOe06gklfRtqZCgZDZD",
                  support_mail: details?.vendor_data?.support_mail,
                });
              }
              if (vendorSlug == "mijana-restaurant-and-café") {
                triggerAddToCart({
                  fb_pixel_code: details?.vendor?.fb_pixel_code,
                  fb_access_token:
                    "EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB",
                  support_mail: details?.vendor?.support_mail,
                  item: item,
                });
              }

              // dynamic for all vendors
              if (
                details?.vendor?.fb_access_token &&
                details?.vendor?.fb_access_token != ""
              ) {
                triggerAddToCart({
                  fb_pixel_code: details?.vendor?.fb_pixel_code,
                  fb_access_token: details?.vendor?.fb_access_token,
                  support_mail: details?.vendor?.support_mail,
                  item: item,
                });
              }
            }

            if (details?.vendor?.snap_pixel_code != "")
              SnapPixel.track("ADD_CART", {
                content_name: item?.product_name,
                item_category: item?.category_name,
                item_ids: [item?.id],
                content_type: "product",
                price: item?.product_price,
                currency: "KWD",
              });

            if (details?.vendor?.vendors_id === "132") {
              TiktokPixel.track("AddToCart", {
                content_type: "product",
                quantity: 1,
                content_name: item?.product_name,
                content_id: item?.id,
                currency: "KWD",
                value: item?.product_price,
              });
            }

            if (
              details?.vendor?.google_tag_code != "" &&
              !/^GTM/.test(details?.vendor?.google_tag_code)
            )
              addCartTag({
                item_id: item?.id,
                item_name: item?.product_name,
                currency: "KWD",
                discount: item?.discount_value,
                item_category: item?.category_name,
                price: item?.product_price,
                quantity: n,
              });

            setSpinLoader(false);
            setCart(response.data);
            if (
              (areaDetails?.type != "delivery" || areaDetails?.area == "") &&
              (areaDetails?.type != "pickup" || areaDetails?.branch == "") &&
              n == 1 &&
              details?.vendor?.home_page_type != "18" &&
              (details?.vendor?.international_delivery === "" ||
                details?.vendor?.international_delivery === "" ||
                internationalDelivery.country_name.toLowerCase() === "kuwait")
            ) {
              history.push(`/area`);
            }
          }
        }
      } else {
        const response = await updateCartQauntity({
          vendorSlug: vendorSlug,
          vendors_id: details?.vendor?.vendors_id,
          area_id: areaDetails?.area_id,
          user_string: localStorage.getItem("userID"),
          quantity: inCart + n,
          branch_id: 87,
          item_id: temp[0]?.item_id,
        });
        if (response?.status) {
          localStorage.setItem("cartTime", new Date());
          if (response.status == false) {
            notify(response.message, response.message_ar, language);
          }
          if (details?.vendor?.fb_pixel_code != "" && n == 1) {
            ReactPixel.track("AddToCart", {
              content_name: item?.product_name,
              content_category: item?.category_name,
              content_ids: [item?.id],
              content_type: "product",
              value: item?.product_price,
              currency: "KWD",
            });

            const time = Date.now();
            const sec = Math.round(time / 1000);

            if (vendorSlug == "butters") {
              triggerAddToCart({
                fb_pixel_code: "546180060531909",
                fb_access_token:
                  "EAAVDp1efPkMBOZBE2DPWrA7he9iJFn9EZBUpd4k3cRjpApcbNMLJgbdmelpI1uApMyxEYoorwBD5ZBDGPL5NWMxXGrKpoAHHxG9NtBMrppMm8YHLRmFgiYVL37nu7PUaO3WPfz4U4K75jIH7eErUZCSRAeJJyQpc88THHEBQGMozZBM9894dBoOe06gklfRtqZCgZDZD",
                support_mail: details?.vendor_data?.support_mail,
              });
            }
            //static for mijana
            if (vendorSlug == "mijana-restaurant-and-café") {
              triggerAddToCart({
                fb_pixel_code: details?.vendor?.fb_pixel_code,
                fb_access_token:
                  "EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB",
                support_mail: details?.vendor?.support_mail,
                item: item,
              });
            }

            //dynamic for all vendors
            if (
              details?.vendor?.fb_access_token &&
              details?.vendor?.fb_access_token != ""
            ) {
              triggerAddToCart({
                fb_pixel_code: details?.vendor?.fb_pixel_code,
                fb_access_token: details?.vendor?.fb_access_token,
                support_mail: details?.vendor?.support_mail,
                item: item,
              });
            }
          }
          if (details?.vendor?.snap_pixel_code != "" && n == 1)
            SnapPixel.track("ADD_CART", {
              content_name: item?.product_name,
              item_category: item?.category_name,
              item_ids: [item?.id],
              content_type: "product",
              price: item?.product_price,
              currency: "KWD",
            });

          if (details?.vendor?.vendors_id === "132" && n == 1) {
            TiktokPixel.track("AddToCart", {
              content_type: "product",
              quantity: 1,
              content_name: item?.product_name,
              content_id: item?.id,
              currency: "KWD",
              value: item?.product_price,
            });
          }

          if (
            details?.vendor?.google_tag_code != "" &&
            !/^GTM/.test(details?.vendor?.google_tag_code) &&
            n == 1
          )
            addCartTag({
              item_id: item?.id,
              item_name: item?.product_name,
              currency: "KWD",
              discount: item?.discount_value,
              item_category: item?.category_name,
              price: item?.product_price,
              quantity: n,
            });
          setSpinLoader(false);
          setCart(response.data);
          if (
            (areaDetails?.type != "delivery" || areaDetails?.area == "") &&
            (areaDetails?.type != "pickup" || areaDetails?.branch == "") &&
            n == 1 &&
            (details?.vendor?.international_delivery === "3" ||
              details?.vendor?.international_delivery === "" ||
              internationalDelivery.country_name.toLowerCase() === "kuwait")
          ) {
            history.push(`/area`);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (cart?.cartItems) {
      const temp = cart?.cartItems?.filter((k, i) => item?.id == k?.product_id);
      if (temp.length == 0) {
        setInCart(0);
      } else {
        let n = 0;
        temp?.forEach((k, i) => {
          n = n + parseInt(k?.quantity);
        });
        setInCart(n);
      }
    } else {
      setInCart(0);
    }
  }, [cart]);

  return (
    <div className="product-category-item">
      <Link to={`/product=${item?.product_slug}`}>
        <div className="product-img-div">
          <div
            style={{
              maxWidth: "90%",
              position: "relative",
              display: "inline-block",
            }}
          >
            {/* <LazyLoadImage
              style={{
                borderRadius:
                  details?.vendor?.home_page_type == "18" ? "0px" : "6px",
              }}
              src={`${item?.image}`}
            ></LazyLoadImage> */}
            <BrokenImage
              style={{
                borderRadius:
                  details?.vendor?.home_page_type == "18" ? "0px" : "6px",
              }}
              src={item?.image}
              fallbackSrc={item?.image}
            />
            {item?.label && (
              <span
                className="offer-label"
                style={{
                  backgroundColor: item.label_color,
                  borderRadius:
                    details?.vendor?.home_page_type == "18" ? "0px" : "6px",
                }}
              >
                {language == "ltr" ? item?.label : item?.label_ar}
              </span>
            )}
          </div>
        </div>
        <div className="product-title">
          <p
            style={{
              fontSize: language == "ltr" ? 17 : 20,
              fontWeight: 600,
              textAlign: "center",
              width: "90%",
            }}
            className="product-name"
          >
            {language === "ltr" ? item?.product_name : item?.product_name_ar}
          </p>
        </div>
        {item?.short_description != "" ? (
          <div className="product-description-div" style={{ marginTop: 10 }}>
            <p className="product-description">
              {language === "ltr"
                ? item?.short_description
                  ?.replace(/(<([^>]+)>)/gi, "")
                  .replace(/\&nbsp;/gi, "")
                  .replace(/\s\s+/g, " ")
                  .replace(/\&#39;/gi, "'")
                : item?.short_description_ar
                  ?.replace(/(<([^>]+)>)/gi, "")
                  .replace(/\&nbsp;/gi, "")
                  .replace(/\s\s+/g, " ")
                  .replace(/\&#39;/gi, "'")}
            </p>
          </div>
        ) : null}
        {details?.vendor?.home_page_type != "18" ? (
          item?.product_type != 0 ? (
            <div className="product-price-div">
              {item?.product_status == 0 ? (
                <p
                  className="buy-get-img"
                  style={{
                    borderRadius: "30px",
                    fontSize: language == "ltr" ? 12 : 15,
                    padding: "0 15px",
                    color: "#818181",
                    border: "2px solid #818181",
                    minWidth: "155px",
                  }}
                >
                  <div>
                    <span>
                      {language === "ltr"
                        ? item?.status_label
                        : item?.status_label_ar}
                    </span>
                  </div>
                </p>
              ) : item?.price_on_selection == 1 ? (
                <Link
                  to={`/product=${item?.product_slug}`}
                  className="buy-get-img "
                  style={{
                    borderRadius: "30px",
                    // borderRadius: "30px",
                    fontSize: language == "ltr" ? 12 : 15,
                    padding: "0 15px",
                    color: "#818181",
                    border: "2px solid #818181",
                    minWidth: "155px",
                  }}
                >
                  {language === "ltr"
                    ? "Price On Selection"
                    : "السعر حسب الاختيار"}
                </Link>
              ) : item?.prodyct_type == 2 ? (
                <Link
                  to={`/product=${item?.product_slug}`}
                  className="buy-get-img "
                  style={{
                    borderRadius: "30px",
                    fontSize: language == "ltr" ? 12 : 15,
                    padding: "0 15px",
                    color: "#818181",
                    border: "2px solid #818181",
                    minWidth: "155px",
                  }}
                >
                  {language === "ltr"
                    ? "Product Registration only"
                    : "حجز المنتج فقط"}
                </Link>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: inCart != 0 ? "center" : "flex-start",
                    justifyContent: "center",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    cursor: "pointer",
                  }}
                >
                  <div
                    onClick={(e) => inCart == 0 && onAddToCartClick(e, 1)}
                    className="product-price"
                    style={{
                      alignItems: "center",
                      fontSize: language == "ltr" ? 14 : 17,
                    }}
                  >
                    <span
                      style={{
                        fontSize: language == "ltr" ? 14 : 17,
                        paddingTop: inCart == 0 ? 4 : 0,
                      }}
                    >
                      <span style={{ fontSize: 14 }}>
                        {item?.product_price ? parseFloat(item?.product_price).toFixed(3) : 0}&nbsp;
                      </span>
                      {language === "rtl" ? "د.ك" : "KD"}
                    </span>
                    {item?.quantity != 0 && item?.discount_applied == 1 && (
                      <del
                        style={{ fontSize: language == "ltr" ? 12 : 15 }}
                        className="product-price-del"
                      >
                        <span style={{ fontSize: 12 }}>
                          {item?.base_price ? parseFloat(item?.base_price).toFixed(3) : 0}&nbsp;
                        </span>
                        {language === "rtl" ? "د.ك" : "KD"}
                      </del>
                    )}
                  </div>
                  {item?.product_status ==
                    0 ? null : item?.price_on_selection == 1 ? null : inCart !=
                      0 ? (
                      <Link
                        onClick={(e) => e.preventDefault()}
                        className="product-price "
                      >
                        <div className="controlbuttondiv">
                          <button
                            className="control-button"
                            onClick={(e) => onAddToCartClick(e, -1)}
                          >
                            <i className="fa fa-minus"></i>
                          </button>
                          <Link
                            onClick={(e) => e.preventDefault()}
                            className="quantity-text"
                          >
                            {spinLoader ? (
                              <Spinner
                                height="16px"
                                size="2.5px"
                                color={details.vendor.vendor_color}
                              />
                            ) : (
                              inCart
                            )}
                          </Link>
                          <button
                            className="control-button"
                            onClick={(e) => onAddToCartClick(e, 1)}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                        </div>
                      </Link>
                    ) : (
                      <span
                        onClick={(e) => inCart == 0 && onAddToCartClick(e, 1)}
                        style={{
                          backgroundColor: details?.vendor?.vendor_color,
                        }}
                        className="cart-image-div"
                      >
                        {spinLoader ? (
                          <div style={{ padding: "0 2px" }}>
                            <Spinner height="14px" size="2px" />
                          </div>
                        ) : (
                          <img
                            src={"pictures/Logo.png"}
                            className="cart-image-add"
                          ></img>
                        )}
                      </span>
                    )}
                </div>
              )}
            </div>
          ) : null
        ) : item?.product_type != 0 ? (
          <>
            {item?.product_status == 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <p
                  className="buy-get-img"
                  style={{
                    borderRadius: "0px",
                    fontSize: language == "ltr" ? 12 : 15,
                    padding: "0 15px",
                    color: "#818181",
                    border: "2px solid #818181",
                    width: "155px",
                  }}
                >
                  <div>
                    <span>
                      {language === "ltr"
                        ? item?.status_label
                        : item?.status_label_ar}
                    </span>
                  </div>
                </p>
              </div>
            ) : item?.price_on_selection == 1 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Link
                  to={`/product=${item?.product_slug}`}
                  className="buy-get-img "
                  style={{
                    borderRadius: "0px",
                    fontSize: language == "ltr" ? 12 : 15,
                    padding: "0 15px",
                    color: "#818181",
                    border: "2px solid #818181",
                    minWidth: "155px",
                  }}
                >
                  {language === "ltr"
                    ? "Price On Selection"
                    : "السعر حسب الاختيار"}
                </Link>
              </div>
            ) : item?.prodyct_type == 2 ? (
              <Link
                to={`/product=${item?.product_slug}`}
                className="buy-get-img "
                style={{
                  borderRadius: "30px",
                  fontSize: language == "ltr" ? 12 : 15,
                  padding: "0 15px",
                  color: "#818181",
                  border: "2px solid #818181",
                  minWidth: "155px",
                }}
              >
                {language === "ltr"
                  ? "Product Registration only"
                  : "حجز المنتج فقط"}
              </Link>
            ) : (
              <div style={{ width: "100%" }}>
                <div className="product-price-div">
                  <div
                    onClick={(e) => inCart == 0 && onAddToCartClick(e, 1)}
                    className="product-price"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: language == "ltr" ? 14 : 17,
                      paddingRight: "0px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: language == "ltr" ? 14 : 17,
                        paddingTop: inCart == 0 ? 4 : 0,
                      }}
                    >
                      <span style={{ fontSize: 14 }}>
                        {item?.product_price ? parseFloat(item?.product_price).toFixed(3) : 0}&nbsp;
                      </span>
                      {language === "rtl" ? "د.ك" : "KD"}
                    </span>
                    {item?.quantity != 0 && item?.discount_applied == 1 && (
                      <del
                        style={{ fontSize: language == "ltr" ? 12 : 15 }}
                        className="product-price-del"
                      >
                        <span style={{ fontSize: 12 }}>
                          {item?.base_price ? parseFloat(item?.base_price).toFixed(3) : 0}&nbsp;
                        </span>
                        {language === "rtl" ? "د.ك" : "KD"}
                      </del>
                    )}
                  </div>
                </div>
                {item?.product_status == 0 ? null : item?.price_on_selection ==
                  1 ? null : inCart != 0 ? (
                    <Link
                      onClick={(e) => e.preventDefault()}
                      className="product-price "
                    >
                      <div
                        className={`controlbuttondiv ${details?.vendor?.home_page_type == "18"
                          ? "fashion-controlbuttondiv"
                          : ""
                          }`}
                      >
                        <button
                          className="control-button"
                          onClick={(e) => onAddToCartClick(e, -1)}
                          style={{
                            borderRadius: "0px",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                        <Link
                          onClick={(e) => e.preventDefault()}
                          className="quantity-text"
                        >
                          {inCart}
                        </Link>
                        <button
                          style={{
                            borderRadius: "0px",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          className="control-button"
                          onClick={(e) => onAddToCartClick(e, 1)}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </Link>
                  ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={(e) => inCart == 0 && onAddToCartClick(e, 1)}
                  >
                    <div className="fashion-add-to-cart">
                      <div className="fashion-add-to-cart-text">
                        {language == "ltr" ? "Add To Bag" : "أضف الى الحقيبة"}
                      </div>
                      <div className="fashion-add-to-cart-img">
                        {spinLoader ? (
                          <div style={{ padding: "0 2px" }}>
                            <Spinner height="14px" size="2px" />
                          </div>
                        ) : (
                          <img
                            src={"pictures/Logo.png"}
                            className="cart-image-add"
                          ></img>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        ) : null}
        {item?.product_type != 0 ? (
          <div className="product-price-div">
            {item?.offer_applied == 1 && (
              <p
                className="buy-get-img "
                onClick={(e) =>
                  inCart == 0 ? onAddToCartClick(e, 1) : e.preventDefault()
                }
                style={{
                  backgroundColor: item?.color,
                  color: "white",
                  border: "none",
                  borderRadius:
                    details?.vendor?.home_page_type != "18" ? "30px" : "0px",
                  fontSize: language == "ltr" ? 12 : 15,
                  padding: "0 15px",
                  minWidth: "155px",
                }}
              >
                <span>
                  {language === "ltr" ? item?.offer_msg : item?.offer_msg_ar}
                </span>
              </p>
            )}
          </div>
        ) : null}
      </Link>
    </div>
  );
}

export default ToggleCategoryProduct;
