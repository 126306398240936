/**
 * @desc This component is the main component for the theme 2 (child/place) of the booking vendor.
 */
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  BookingSetContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../../App";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { useState } from "react";
import { BOOK_URL } from "../../../services/constants";
import $ from "jquery";
import { add } from "../../../api";

function PackagePlaceSearch({
  setStepper,
  stepper,
  bookingDetails,
  setBookingDetails,
  openCal,
  setOpenCal,
  tabOpen,
  setTabOpen,
}) {
  const history = useHistory();
  const vendorSlug = useContext(VendorSlugContext);
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const [contUnavail, setContUnavail] = useState([]);
  const { bookingSets, setBookingSets } = useContext(BookingSetContext);

  useEffect(() => {
    if (bookingDetails?.date?.length !== 0)
      setTimeout(
        () =>
          onDateSelect(
            `date${moment(bookingDetails?.date[0])
              ?.locale("en")
              .format("DDMMYYYY")}`
          ),
        100
      );
  }, []);

  const onDateSelect = (k) => {
    $("#date-scroller").animate(
      {
        scrollLeft:
          $(`#${k}`)?.position()?.left +
          $(`#${k}`)?.width() / 2 +
          $("#date-scroller")?.scrollLeft() -
          $("#date-scroller")?.width() / 2,
      },
      "slow"
    );
  };

  const sortDays = (i, j) => {
    return i?.id < j?.id ? -1 : 1;
  };

  const onDaysClick = (k) => {
    let temp = bookingDetails?.week;
    if (!bookingDetails?.week?.some((t) => t?.id == k?.id)) {
      temp?.push(k);
    } else {
      temp = temp.filter((t) => t?.id !== k?.id);
    }
    temp = temp.sort(sortDays);
    setBookingDetails((book) => ({
      ...book,
      week: temp,
    }));
  };

  const sortDate = (i, j) => {
    return i < j ? -1 : 1;
  };


  const onDateClick = (k) => {
    let temp = bookingDetails?.date;
    if (temp?.length == 0) {
      temp?.push(k);
    } else if (
      temp?.length == 1 &&
      bookingDetails?.date?.some(
        (t) =>
          moment(t)?.locale("en")?.format("MMMMYYYY") ==
          moment(k)?.locale("en")?.format("MMMMYYYY")
      )
    ) {
      temp = [];
    } else if (
      !bookingDetails?.date?.some(
        (t) =>
          moment(t)?.locale("en")?.format("MMMMYYYY") ==
          moment(k)?.locale("en")?.format("MMMMYYYY")
      )
    ) {
      let i = moment(k)?.startOf("month");
      let l = moment(temp[0])?.startOf("month");
      let m = moment(temp[temp.length - 1])?.startOf("month");
      if (i < l) {
        while (true) {
          if (moment(i) < moment(l)) {
            temp.push(moment(i)?.startOf("month"));
            i?.add(1, "month");
          } else break;
        }
      } else {
        while (true) {
          if (moment(m) < moment(i)) {
            m?.add(1, "month");
            temp.push(moment(m)?.startOf("month"));
          } else break;
        }
      }
    } else {
      temp = [];
      temp.push(k);
    }
    temp = temp?.sort(sortDate);
    setBookingDetails((book) => ({
      ...book,
      time: [],
      week: [],
      date: temp,
    }));
  };

  const checkNext = () => {
    if (
      bookingDetails?.time?.length != 0 &&
      bookingDetails?.date?.length != 0 &&
      bookingDetails?.week?.length != 0
    )
      return true;
    else return false;
  };

  const onMinus = () => {
    if (bookingDetails?.attendee != 1) {
      setBookingDetails((a) => ({ ...a, attendee: a?.attendee - 1 }));
    }
  };

  const onPlus = () => {
    if (bookingDetails?.attendee != bookingSets?.maxAttend) {
      setBookingDetails((a) => ({ ...a, attendee: a?.attendee + 1 }));
    }
  };

  const sortTime = (i, j) => {
    return i?.slot_start_time < j?.slot_start_time ? -1 : 1;
  };

  const onTimeClick = (i) => {
    if (bookingSets?.is_multi_slot == 1 && bookingSets?.max_slot != 1) {
      let temp = bookingDetails?.time;
      if (
        !bookingDetails?.time?.some(
          (t) => t?.slot_start_time == i?.slot_start_time
        )
      ) {
        if (temp?.length < Number(bookingSets?.max_slot)) temp?.push(i);
      } else {
        temp = temp.filter((f) => f?.slot_start_time !== i?.slot_start_time);
      }
      temp = temp?.sort(sortTime);
      setBookingDetails((s) => ({
        ...s,
        time: temp,
      }));
    } else {
      let temp = bookingDetails?.time;
      if (
        !bookingDetails?.time?.some(
          (t) => t?.slot_start_time == i?.slot_start_time
        )
      ) {
        temp = [i];
      } else temp = [];
      setBookingDetails((s) => ({
        ...s,
        time: temp,
      }));
    }
  };

  const createTemp = (p) => {
    let temp = p;

    temp = temp.filter((place) => {
      let { booking_place_id, available_slot } = place;
      available_slot = Object.keys(available_slot);

      return !bookingSets.cronBooked.some((cron) => {
        let isPlaceSame = cron.placeId == booking_place_id;
        let isDatesSame = cron.dates.some((a) => available_slot.includes(a));

        let isTimeSame = cron.timeSlots.some((a) => {
          return bookingDetails.time.some(
            (b) =>
              a.slot_start_time == b.slot_start_time &&
              a.slot_end_time == b.slot_end_time
          );
        });

        return isPlaceSame && isDatesSame && isTimeSame;
      });
    });

    if (temp?.length != 0) {
      setBookingSets((set) => ({
        ...set,
        branchAvail: false,
        places: temp,
        base: p,
      }));
    } else {
      setBookingSets((set) => ({
        ...set,
        branchAvail: true,
        base: p,
        places: [],
      }));
    }
  };

  const onSearchClick = () => {
    axios
      .post(
        `${BOOK_URL}/search`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          vendor_slug: vendorSlug,
          booking_vendor_id: details?.vendor?.booking_vendor_id,
          // booking_date: bookingDetails?.date?.locale("en").format("YYYY-MM-DD"),
          booking_term: "2",
          slot: bookingDetails?.time?.map((t) => ({
            slot_start_time: t?.slot_start_time,
            slot_end_time: t?.slot_end_time,
          })),
          booking_month: bookingDetails?.date?.map((d) =>
            d?.locale("en")?.format("YYYY-MM")
          ),
          booking_days: bookingDetails?.week?.map((k) => k?.id).join(","),
        })
      )
      .then((res) => {
        if (res.data.data.status) {
          let temp = Object.values(res.data.data.details)?.map(
            (d) => d.available_place
          );
          temp = [].concat.apply([], temp);
          if (bookingSets.cronBooked.length !== 0) createTemp(temp);
          else
            setBookingSets((set) => ({
              ...set,
              branchAvail: false,
              places: temp,
              base: temp,
            }));
        } else {
          setBookingSets((set) => ({
            ...set,
            branchAvail: true,
            base: [],
          }));
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (bookingSets.base.length !== 0) {
      let temp = bookingSets?.base;
      createTemp(temp);
    }
  }, [bookingSets.cronBooked]);

  const onCaledarSelect = (i, j) => {
    let temp = [];
    while (true) {
      if (moment(i) <= moment(j)) {
        temp.push(moment(i)?.startOf("month"));
        i?.add(1, "month");
      } else break;
    }
    temp.sort(sortDate);
    setBookingDetails((a) => ({
      ...a,
      time: [],
      week: [],
      date: temp,
    }));
  };

  return (
    <>
      <div style={{ paddingBottom: 10 }}>
        <div
          className="checkout-step-one"
          style={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <div className="checkout-one" style={{ backgroundColor: "#f5f5f9" }}>
            {tabOpen >= 4 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0px 16px 16px",
                    backgroundColor: "white",
                  }}
                >
                  <p
                    style={{
                      fontSize: language == "ltr" ? 20 : 23,
                      fontWeight: 600,
                    }}
                  >
                    {language == "ltr" ? "Booking Details" : "معلومات الحجز"}
                  </p>
                  <p
                    style={{
                      color: details?.vendor?.vendor_color,
                      fontSize: language == "ltr" ? 18 : 21,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setBookingSets((set) => ({
                        ...set,
                        booking_search: false,
                      }));
                      setTabOpen(() => 3);
                    }}
                  >
                    {language == "ltr" ? "Change" : "تغيير"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 16px 16px",
                    backgroundColor: "white",
                    borderBottom: "8px solid #f6f7fb",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <p style={{ display: "flex" }}>
                      <i
                        style={{
                          fontSize: 18,
                        }}
                        className="far fa-hourglass"
                      ></i>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexGrow: 1,
                        }}
                      >
                        <span
                          style={{
                            fontSize: language == "ltr" ? 16 : 19,
                            fontWeight: 600,
                            flexShrink: 0,
                          }}
                        >
                          {language == "ltr" ? "Duration" : "المدة"}
                        </span>
                        <span
                          style={{
                            fontSize: language == "ltr" ? 15 : 18,
                            fontWeight: 600,
                            color: "gray",
                            textAlign: language == "ltr" ? "right" : "left",
                          }}
                          id="block-addr"
                        >
                          {bookingDetails?.date?.map((k, i) =>
                            i == 0
                              ? moment(k)
                                ?.locale(language == "ltr" ? "en" : "ar")
                                ?.format("MMMM")
                              : `, ${moment(k)
                                ?.locale(language == "ltr" ? "en" : "ar")
                                ?.format("MMMM")}`
                          )}
                        </span>
                      </p>
                    </p>
                  </div>
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        display: "flex",
                        marginTop: 15,
                      }}
                    >
                      <i
                        style={{
                          fontSize: 18,
                        }}
                        className="fas fa-calendar-week"
                      ></i>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexGrow: 1,
                        }}
                      >
                        <span
                          style={{
                            fontSize: language == "ltr" ? 16 : 19,
                            fontWeight: 600,
                            width: "180px",
                            flexShrink: 0,
                          }}
                        >
                          {language == "ltr"
                            ? "Days of the Week"
                            : "أيام الأسبوع"}
                        </span>
                        <span
                          style={{
                            fontSize: language == "ltr" ? 15 : 18,
                            fontWeight: 600,
                            color: "gray",
                            textAlign: language == "ltr" ? "right" : "left",
                          }}
                          id="block-addr"
                        >
                          {bookingDetails?.week?.map((k, i) =>
                            i == 0
                              ? language == "ltr"
                                ? k?.name
                                : k?.name_ar
                              : `, ${language == "ltr" ? k?.name : k?.name_ar}`
                          )}
                        </span>
                      </p>
                    </p>
                  </div>
                  <div style={{ width: "100%", marginTop: 15 }}>
                    <p style={{ display: "flex" }}>
                      <i
                        style={{
                          fontSize: 18,
                        }}
                        className="far fa-clock"
                      ></i>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexGrow: 1,
                        }}
                      >
                        <span
                          style={{
                            fontSize: language == "ltr" ? 16 : 19,
                            fontWeight: 600,
                          }}
                        >
                          {language == "ltr" ? "Time" : "الوقت"}
                        </span>
                        <span
                          style={{
                            fontSize: language == "ltr" ? 15 : 18,
                            fontWeight: 600,
                            color: "gray",
                          }}
                          id="block-addr"
                        >
                          {bookingDetails?.time?.map((i, k) => (
                            <>
                              {k == 0 ? (
                                ""
                              ) : (
                                <>
                                  , <br></br>
                                </>
                              )}
                              <span
                                style={{ fontSize: 15 }}
                                className="number-span"
                              >
                                {i.start_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.start_time_meridiem
                                : i?.start_time_meridiem_ar}
                              {" - "}
                              <span
                                style={{ fontSize: 15 }}
                                className="number-span"
                              >
                                {i.end_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.end_time_meridiem
                                : i?.end_time_meridiem_ar}
                            </>
                          ))}
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="booking-single-div">
                  <div className="booking-flex-div">
                    <p className="booking-sub-title">
                      {language == "ltr" ? "Duration" : "المدة"}
                    </p>
                    <p className="booking-info-text">
                      {bookingDetails?.date?.map((k, i) =>
                        i == 0
                          ? moment(k)
                            ?.locale(language == "ltr" ? "en" : "ar")
                            ?.format("MMMM")
                          : `, ${moment(k)
                            ?.locale(language == "ltr" ? "en" : "ar")
                            ?.format("MMMM")}`
                      )}
                    </p>
                  </div>
                  <div className="pt-3">
                    <p
                      className="booking-info-text open-calendar"
                      onClick={() => setOpenCal((a) => !a)}
                    >
                      {!openCal
                        ? language == "ltr"
                          ? "Open Calendar"
                          : "افتح التقويم"
                        : language == "ltr"
                          ? "Close Calendar"
                          : "اغلاق الرزنامة"}
                    </p>
                    <div
                      style={openCal ? {} : { display: "none" }}
                      className="booking-calendar-container"
                    >
                      <Calendar
                        calendarType="Arabic"
                        minDetail="year"
                        maxDetail="year"
                        onChange={(e) => {
                          setBookingDetails((a) => ({
                            ...a,
                            time: [],
                            week: [],
                            date: [moment(e)?.locale("en")],
                          }));

                          setOpenCal((a) => !a);
                          setTabOpen((a) => 2);
                          setTimeout(() => {
                            onDateSelect(
                              `date${moment(e)
                                .locale("en")
                                .startOf("month")
                                .format("DDMMYYYY")}`
                            );
                          }, 100);
                        }}
                        value={bookingDetails?.date[0]?.toDate()}
                        formatDay={(l, d) => {
                          return moment(d)?.locale("en")?.format("D");
                        }}
                        formatMonthYear={(l, d) => {
                          return `${moment(d)
                            ?.locale(l)
                            ?.format("MMMM")} ${moment(d)
                              ?.locale("en")
                              ?.format("YYYY")}`;
                        }}
                        formatYear={(l, d) => {
                          return moment(d)?.locale("en")?.format("YYYY");
                        }}
                        minDate={bookingSets?.dateNow?.toDate()}
                        maxDate={moment(
                          Object.values(bookingSets?.booking_month).slice(-1)[0]
                            ?.id,
                          "YYYY-MM"
                        ).toDate()}
                        locale={language == "ltr" ? "en" : "ar"}
                      />
                    </div>
                    <div
                      style={!openCal ? {} : { display: "none" }}
                      className="date-scroller"
                      id="date-scroller"
                    >
                      {[
                        ...Array(
                          10
                        ),
                      ].map((k, i) =>
                        moment(bookingSets?.dateNow)
                          .locale("en")
                          .add(i, "months")
                          .startOf("month") >
                          moment(
                            Object.values(bookingSets?.booking_month).slice(-1)[0]
                              ?.id,
                            "YYYY-MM"
                          ).startOf("month") ? null : (
                          <span
                            id={`date${moment(bookingSets?.dateNow)
                              .locale("en")
                              .add(i, "months")
                              .startOf("month")
                              .format("DDMMYYYY")}`}
                            onClick={() => {
                              onDateClick(
                                moment(bookingSets?.dateNow)
                                  .locale("en")
                                  .add(i, "months")
                                  ?.startOf("month")
                              );
                              setTabOpen((t) => 2);
                              onDateSelect(
                                `date${moment(bookingSets?.dateNow)
                                  .locale("en")
                                  .add(i, "months")
                                  .startOf("month")
                                  .format("DDMMYYYY")}`
                              );
                            }}
                            className={`date-selector ${bookingDetails?.date?.some(
                              (k) =>
                                k?.locale("en")?.format("MMMMYYYY") ==
                                moment(bookingSets?.date)
                                  .locale("en")
                                  .add(i, "months")
                                  ?.format("MMMMYYYY")
                            )
                              ? "booking-slot-active"
                              : ""
                              }`}
                          >
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                {moment(bookingSets?.dateNow)
                                  .locale(language == "ltr" ? "en" : "ar")
                                  .add(i, "months")
                                  ?.format("MMMM")}
                              </span>

                              {language == "ltr" ? (
                                <span>
                                  {moment(bookingSets?.dateNow)
                                    .locale("en")
                                    .add(i, "months")
                                    ?.format("YYYY")}
                                </span>
                              ) : (
                                <span>
                                  {moment(bookingSets?.dateNow)
                                    .locale("en")
                                    .add(i, "months")
                                    ?.format("YYYY")}
                                </span>
                              )}
                            </span>
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="booking-single-div">
                  <div className="booking-flex-div">
                    <p className="booking-sub-title">
                      {language == "ltr" ? "Days of the Week" : "أيام الأسبوع"}
                    </p>
                  </div>
                  <div
                    style={
                      !openCal && tabOpen >= 2
                        ? { padding: "0px 0px 15px" }
                        : { display: "none" }
                    }
                    className="date-scroller"
                  >
                    {bookingSets?.week_days?.map((k) => (
                      <span
                        style={{
                          width: 90,
                          maxWidth: 100,
                          minWidth: 90,
                          padding: "10px 0",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          onDaysClick(k);
                          setTabOpen(() => 3);
                        }}
                        className={`date-selector ${bookingDetails?.week?.some((i) => i?.id == k?.id)
                          ? "booking-slot-active"
                          : ""
                          }`}
                      >
                        {language == "ltr" ? k?.name : k?.name_ar}
                      </span>
                    ))}
                  </div>
                </div>
                <div className="booking-single-div">
                  <div className="booking-flex-div">
                    <p className="booking-sub-title">
                      {language == "ltr" ? "Time" : "الوقت"}
                    </p>
                    <p className="booking-info-text">
                      {
                        bookingDetails?.time?.length == 0
                          ? language == "ltr"
                            ? "Any"
                            : "إختر الوقت"
                          : bookingDetails?.time?.map((i, k) => (
                            <>
                              {k == 0 ? "" : ", "}
                              <span
                                style={{ fontSize: 18 }}
                                className="number-span"
                              >
                                {i.start_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.start_time_meridiem
                                : i?.start_time_meridiem_ar}
                            </>
                          ))
                      }
                    </p>
                  </div>
                  <div
                    className="pt-3"
                    style={!openCal && tabOpen >= 3 ? {} : { display: "none" }}
                  >
                    {true ? (
                      <div className="booking-time-div">
                        {bookingSets?.morning?.length != 0 && (
                          <div className="booking-time-period">
                            <p
                              className="booking-time-text"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {language == "ltr" ? "☀️ Morning" : "صباحاً ☀️"}
                            </p>
                            <div className="booking-time-slot-div">
                              {bookingSets?.morning?.map((i, k) => (
                                <button
                                  onClick={() => {
                                    onTimeClick(i);
                                    setTabOpen(() => 3);
                                  }}
                                  className={`booking-time-slot ${bookingDetails?.time?.some(
                                    (t) =>
                                      t?.slot_start_time == i?.slot_start_time
                                  )
                                    ? "booking-slot-active"
                                    : ""
                                    } 
                            ${bookingSets?.is_multi_slot == 1 &&
                                      bookingSets?.max_slot != 1
                                      ? bookingDetails?.time?.length ==
                                        bookingSets?.max_slot &&
                                        !bookingDetails?.time?.some(
                                          (t) =>
                                            t?.slot_start_time == i?.slot_start_time
                                        )
                                        ? "booking-time-booked"
                                        : ""
                                      : ""
                                    }
                            `}
                                >
                                  <span
                                    style={{ fontSize: 12 }}
                                    className="number-span"
                                  >
                                    {i.start_time}
                                  </span>{" "}
                                  {language == "ltr"
                                    ? i?.start_time_meridiem
                                    : i?.start_time_meridiem_ar}{" "}
                                  -{" "}
                                  <span
                                    style={{ fontSize: 12 }}
                                    className="number-span"
                                  >
                                    {i.end_time}
                                  </span>{" "}
                                  {language == "ltr"
                                    ? i?.end_time_meridiem
                                    : i?.end_time_meridiem_ar}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                        {bookingSets?.evening?.length != 0 && (
                          <div
                            className="booking-time-period"
                            style={{
                              paddingTop:
                                bookingSets?.morning?.length == 0 ? 0 : 20,
                            }}
                          >
                            <p
                              className="booking-time-text"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {language == "ltr" ? "🌙 Evening" : "مساءً 🌙"}
                            </p>
                            <div className="booking-time-slot-div">
                              {bookingSets?.evening?.map((i, k) => (
                                <button
                                  onClick={() => {
                                    onTimeClick(i);
                                    setTabOpen(() => 3);
                                  }}
                                  className={`booking-time-slot ${bookingDetails?.time?.some(
                                    (t) =>
                                      t?.slot_start_time == i?.slot_start_time
                                  )
                                    ? "booking-slot-active"
                                    : ""
                                    } ${bookingSets?.is_multi_slot == 1 &&
                                      bookingSets?.max_slot != 1
                                      ? bookingDetails?.time?.length ==
                                        bookingSets?.max_slot &&
                                        !bookingDetails?.time?.some(
                                          (t) =>
                                            t?.slot_start_time ==
                                            i?.slot_start_time
                                        )
                                        ? "booking-time-booked"
                                        : ""
                                      : ""
                                    }`}
                                >
                                  <span
                                    style={{ fontSize: 12 }}
                                    className="number-span"
                                  >
                                    {i.start_time}
                                  </span>{" "}
                                  {language == "ltr"
                                    ? i?.start_time_meridiem
                                    : i?.start_time_meridiem_ar}{" "}
                                  -{" "}
                                  <span
                                    style={{ fontSize: 12 }}
                                    className="number-span"
                                  >
                                    {i.end_time}
                                  </span>{" "}
                                  {language == "ltr"
                                    ? i?.end_time_meridiem
                                    : i?.end_time_meridiem_ar}
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          padding: "0px 10px",
                          color: details?.vendor?.vendor_color,
                          fontSize: 18,
                          textAlign: "center",
                        }}
                      >
                        {language == "ltr"
                          ? `No Slots Available. Please Choose Another Date or ${details?.vendor?.place_label}`
                          : "لا يوجد وقت متوفر للحجز الآن يرجى البحث بخيارات مختلفة"}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {tabOpen < 4 ? null : (
              <div
                className="booking-single-div"
                style={{ borderBottom: "none" }}
              >
                <div className="booking-flex-div">
                  <p className="booking-sub-title">
                    {language == "ltr"
                      ? details?.vendor?.place_label
                      : details?.vendor?.place_label_ar}
                  </p>
                  <p className="booking-info-text">
                    {bookingDetails?.branch == ""
                      ? language == "ltr"
                        ? "All"
                        : "إختر المكان"
                      : language == "ltr"
                        ? bookingDetails?.branch?.name
                        : bookingDetails?.branch?.name_ar}
                  </p>
                </div>
                <div
                  className="pt-3"
                  style={!openCal && tabOpen >= 4 ? {} : { display: "none" }}
                >
                  {bookingSets?.branchAvail ? (
                    <div
                      style={{
                        padding: "0px 10px",
                        color: details?.vendor?.vendor_color,
                        fontSize: 18,
                        textAlign: "center",
                      }}
                    >
                      {language == "ltr"
                        ? `No ${details?.vendor?.place_label} Available. Please Choose Another Date or Time`
                        : `لا ${details?.vendor?.place_label_ar} متاحة. الرجاء اختيار تاريخ أو وقت آخر`}
                    </div>
                  ) : (
                    <>
                      <ul
                        style={tabOpen == 4 ? {} : { display: "none" }}
                        className="booking-branch-list"
                      >
                        {bookingSets?.places?.map((k) => (
                          <li
                            onClick={() => {
                              let session =
                                Object.keys(k?.available_slot)?.length *
                                bookingDetails?.time?.length;
                              add({
                                vendorId: details.vendor.booking_vendor_id,
                                userId: localStorage.getItem("userID"),
                                dates: Object.keys(k?.available_slot),
                                timeSlots: bookingDetails?.time?.map((t) => ({
                                  slot_start_time: t?.slot_start_time,
                                  slot_end_time: t?.slot_end_time,
                                })),
                                placeId: k?.booking_place_id,
                                mainVendor: k?.booking_vendor_id,
                              });
                              setBookingSets((sets) => ({
                                ...sets,
                                selectedPlace: {
                                  name: k?.name,
                                  name_ar: k?.name_ar,
                                  // vendor_name: k?.name,
                                  // vendor_name_ar: k?.name_ar,
                                  description: k?.description,
                                  description_ar: k?.description_ar,
                                  unit_price: k?.amount,
                                  price: k?.total_amount,
                                  image: k?.place_image,
                                  latitude: k?.latitude,
                                  longitude: k?.longitude,
                                  google_map_link: k?.google_map_url,
                                  mainVendor: k?.booking_vendor_id,
                                  k,
                                  session,
                                },
                                placeClicked: true,
                              }));
                            }}
                            className="booking-branch"
                          >
                            <img
                              className="booking-branch-img"
                              src={k?.place_image}
                            ></img>
                            <div className="booking-branch-details">
                              <div className="booking-branch-title">
                                {language == "ltr" ? k?.name : k?.name_ar}
                              </div>
                              <div className="booking-branch-desc">
                                {language == "ltr"
                                  ? k?.description
                                  : k?.description_ar}
                              </div>
                              <div className={`booking-branch-button`}>
                                <button
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopPropagation();
                                    let session =
                                      Object.keys(k?.available_slot)?.length *
                                      bookingDetails?.time?.length;
                                    setBookingDetails((book) => ({
                                      ...book,
                                      branch: {
                                        ...k,
                                        session: session,
                                      },
                                    }));
                                    add({
                                      vendorId:
                                        details.vendor.booking_vendor_id,
                                      userId: localStorage.getItem("userID"),
                                      dates: Object.keys(k?.available_slot),
                                      timeSlots: bookingDetails?.time?.map(
                                        (t) => ({
                                          slot_start_time: t?.slot_start_time,
                                          slot_end_time: t?.slot_end_time,
                                        })
                                      ),
                                      placeId: k?.booking_place_id,
                                      mainVendor: k?.booking_vendor_id,
                                    });
                                    setStepper((step) => step + 1);
                                  }}
                                >
                                  <span>
                                    {k?.total_amount ? parseFloat(k?.total_amount)
                                      .toFixed(3)
                                      .toLocaleString("en") : 0}
                                    &nbsp;
                                  </span>
                                  {language === "rtl" ? "د.ك" : "KD"}
                                  <img
                                    // style={{
                                    //   width: 20,
                                    //   height: "25px",
                                    //   objectFit: "cover",
                                    // }}
                                    src={"pictures/Logo.png"}
                                    className="cart-image-add"
                                  ></img>
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div
                        style={
                          tabOpen > 4
                            ? { padding: "0px 0px 15px" }
                            : { display: "none" }
                        }
                        className="date-scroller"
                      >
                        {bookingSets?.places?.map((k) => (
                          <span
                            style={{
                              width: 165,
                              maxWidth: 165,
                              padding: "10px 0",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setBookingDetails((book) => ({
                                ...book,
                                branch: {
                                  ...k,
                                },
                              }));
                            }}
                            className={`date-selector ${k?.vendors_id ==
                              bookingDetails?.branch?.vendors_id
                              ? "booking-slot-active"
                              : ""
                              }`}
                          >
                            {language == "ltr" ? k?.name : k?.name_ar}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {tabOpen < 4
        ? checkNext() && (
          <div
            className="bottom-button iphone-issue"
            style={
              {}
            }
          >
            <Link
              onClick={(e) => {
                e.preventDefault();
                if (checkNext()) {
                  onSearchClick();
                  setBookingDetails((book) => ({
                    ...book,
                    branch: "",
                    place: 0,
                  }));
                  setBookingSets((set) => ({
                    ...set,
                    places: [],
                    booking_search: true,
                  }));
                  setTabOpen(() => 4);
                }
              }}
              className="text-center checkout-button "
            >
              {checkNext()
                ? language == "ltr"
                  ? `Search`
                  : "بحث"
                : language == "ltr"
                  ? "Select all the fields"
                  : "حدد جميع الحقول"}
            </Link>
          </div>
        )
        : checkNext() && (
          <div
            className="bottom-button iphone-issue"
            style={
              {}
            }
          >
            <Link
              onClick={(e) => {
                e.preventDefault();
                setTabOpen(() => 3);
                setBookingSets((set) => ({
                  ...set,
                  booking_search: false,
                  branchAvail: false,
                }));
              }}
              className="text-center checkout-button"
              style={{
                backgroundColor: "white",
                border: `3px solid ${details?.vendor?.vendor_color}`,
                color: `${details?.vendor?.vendor_color}`,
              }}
            >
              {language == "ltr" ? "Back" : "تراجع"}
            </Link>
          </div>
        )}
    </>
  );
}

export default PackagePlaceSearch;
