import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  AreaContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../../App";
import axios from "axios";
import { API_URL } from "../../../services/constants";
import SearchNone from "../../HomePage/components/SearchNone";
import ModalPickup from "../../ModalPickup";
import moment from "moment";
import SearchIcon from "../../../SVGs/SearchIcon";
import Spinner from "../../../SVGs/Spinner";
import $ from "jquery";

const PickupArea = () => {
  const [branchSearch, setBranchSearch] = useState("");
  const [branchs, setBranchs] = useState([]);
  const history = useHistory();
  const details = useContext(VendorContext);
  const [active, setActive] = useState("");
  const vendorSlug = useContext(VendorSlugContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [popupDetails, setPopupDetails] = useState({
    remove_items: [],
    show_popup: 0,
  });
  const branchSearchChange = (e) => {
    setBranchSearch(e.target.value);
  };
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  useEffect(() => {
    if (areaDetails.data.branch) {
      if (branchSearch == "") setBranchs(areaDetails.data.branch);
      else {
        let temp = areaDetails.data.branch.filter(
          (k, i) =>
            k?.name?.toUpperCase().indexOf(branchSearch?.toUpperCase()) > -1 ||
            k?.arabic_name?.toUpperCase().indexOf(branchSearch?.toUpperCase()) >
              -1
        );
        setBranchs(temp);
      }
    }
  }, [areaDetails.data.branch, branchSearch]);

  const onBranchSelect = (eng, arab, key, area) => {
    setTimeout(() => {
      setLoading(true);
    }, 500);
    setActive((a) => eng);
    axios
      .post(
        `${API_URL}/change-area`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          vendor_id: details?.vendor?.vendors_id,
          area_id: area,
          vendor_slug: vendorSlug,
          user_string: localStorage.getItem("userID"),
        })
      )
      .then((res) => {
        let temp = res.data.data;
        if (temp?.show_popup == 0) {
          let temp = areaDetails?.data?.branch?.filter((k, i) => k?.id == key);
          temp = temp[0];
          let start, end;
          if (temp?.office_end_time == "00:00:00") {
            start = new Date(
              new Date().setHours(...temp?.office_start_time?.split(":"))
            );
            end = new Date(new Date().setHours(23, 59, 59));
          } else {
            start = new Date(
              new Date().setHours(...temp?.office_start_time?.split(":"))
            );
            const dummyEnd = new Date(
              new Date().setHours(...temp?.office_end_time?.split(":"))
            );
            end =
              vendorSlug !== "alawael-bilingual-school"
                ? dummyEnd
                : new Date(dummyEnd.setMinutes(dummyEnd.getMinutes() - 30));
          }

          let current = new Date();
          if (vendorSlug !== "alawael-bilingual-school") {
            current.setHours(current.getHours() + 2);
          }
          const dummyStart = new Date(start);
          if (!(start > current || current > end)) {
            setAreaDetails({
              ...areaDetails,
              branch: eng,
              minimum: "",
              area_id: area,
              shopOpen: 1,
              now: 1,
              area: "",
              ar_area: "",
              ar_branch: arab,
              branch_id: key,
              deliveryTiming: getWithinValue(end),
              ar_deliveryTiming: getWithinValue(end, true),
              getDeliveryTiming: moment().add(120, "minutes").toDate(),
              laterDeliveryTiming: moment().add(120, "minutes").toDate(),
              branchForArea: {
                id: key,
                english: eng,
                arabic: arab,
                end:
                  temp?.office_end_time == "00:00:00"
                    ? moment("23:59:59", "HH:mm:ss")
                    : moment(temp?.office_end_time, "HH:mm:ss"),
                start: moment(temp?.office_start_time, "HH:mm:ss"),
              },
            });
          } else {
            setAreaDetails({
              ...areaDetails,
              branch: eng,
              minimum: "",
              area_id: area,
              shopOpen: 2,
              now: 2,
              ar_branch: arab,
              branch_id: key,
              area: "",
              ar_area: "",
              deliveryTiming: "24 Hours",
              ar_deliveryTiming: "24 ساعات",
              getDeliveryTiming: getOutValue(dummyStart, current),
              laterDeliveryTiming: getOutValue(start, current),
              branchForArea: {
                id: key,
                english: eng,
                arabic: arab,
                end:
                  temp?.office_end_time == "00:00:00"
                    ? moment("23:59:59", "HH:mm:ss")
                    : moment(temp?.office_end_time, "HH:mm:ss"),
                start: moment(temp?.office_start_time, "HH:mm:ss"),
              },
            });
          }
          setLoading(false);

          if (history?.location?.state?.from == "prdetails") {
            history.goBack();
          } else {
            history.push(`/`);
          }
        } else {
          setLoading(false);
          setPopupDetails((pop) => ({
            key: key,
            eng: eng,
            arab: arab,
            area: area,
            show_popup: temp?.show_popup,
          }));
        }
      })
      .catch((e) => console.log(e));
  };
  const getWithinValue = (end, lng) => {
    if (vendorSlug !== "alawael-bilingual-school") {
      return lng ? `2 ساعات` : "2 Hours";
    } else {
      return moment(new Date(end)).locale("en").format("hh:mm A");
    }
  };
  const getOutValue = (start, current) => {
    if (vendorSlug !== "alawael-bilingual-school") {
      if (start > current) {
        const value = new Date(start.setHours(start.getHours() + 2));
        return value;
      } else {
        const value = new Date(start.setDate(start.getDate() + 1));
        return value;
      }
    } else {
      if (start > current) {
        const value = new Date(start.setMinutes(start.getMinutes() + 30));
        return value;
      } else {
        const value = new Date(start.setDate(start.getDate() + 1));
        return value;
      }
    }
  };
  const moveup = () => {
    $("html, body").animate(
      {
        scrollTop:
          $(`#pickupSearch`).offset().top - $(`#pickupSearch`).offset().top,
      },
      "slow"
    );
  };
  return (
    <div className="delivery-areas-mainDiv">
      <div
        className={`delivery-area-search  ${
          details?.vendor?.home_page_type === "18" && "fashion-theme-border"
        }`}
      >
        <SearchIcon color={details?.vendor?.vendor_color} />
        <input
          onChange={(e) => branchSearchChange(e)}
          type="search"
          placeholder={
            language === "ltr" ? "Search branch" : "بحث بأسماء الأفرع"
          }
          id="pickupSearch"
          onFocus={moveup}
          className="delivery-area-search-input"
          value={branchSearch}
        ></input>
      </div>
      <div className="branch-list-container">
        <ul className="branch-list">
          {branchs?.length != 0
            ? branchs?.map((branch, i) => (
                <li
                  className={`branch-list-item ${
                    active == branch?.name ? "area_border" : ""
                  }`}
                >
                  <Link
                    onClick={(e) => {
                      if (branch?.availability_status == 1) {
                        e.preventDefault();
                        onBranchSelect(
                          branch?.name,
                          branch?.arabic_name,
                          branch?.id,
                          branch?.area_ids?.length == 0
                            ? 1
                            : branch?.area_ids[0]
                        );
                      }
                    }}
                    className="branch-details"
                  >
                    <h2 className="branch-name">
                      {language === "ltr" ? branch?.name : branch?.arabic_name}
                    </h2>
                    <p className="branch-address">
                      {language === "ltr"
                        ? branch?.address
                        : branch?.arabic_address}
                    </p>
                    {branch?.availability_status != 1 && (
                      <p
                        style={{
                          color: " #ce1414",
                          fontSize: language === "ltr" ? "12px" : "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          marginTop: "10px",
                        }}
                      >
                        {language === "ltr" ? "STORE IS BUSY" : "المتجر مشغول "}
                      </p>
                    )}
                  </Link>
                  <Link to={`/branch=${i}`} className="branch-info">
                    <span>i</span>
                  </Link>
                </li>
              ))
            : branchSearch != "" && (
                <SearchNone searchText={branchSearch}></SearchNone>
              )}
        </ul>
      </div>
      {popupDetails?.show_popup == 1 ? (
        <ModalPickup
          popupDetails={popupDetails}
          setPopupDetails={setPopupDetails}
        ></ModalPickup>
      ) : null}
      {loading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "8",
          }}
          className="order-spinner-background"
        >
          <Spinner
            height="50px"
            color={details?.vendor?.vendor_color}
            size="6px"
          />
        </div>
      )}
    </div>
  );
};

export default PickupArea;
