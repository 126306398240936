import axios from "axios";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { deleteB } from "../../../api";
import {
  BookingSetContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../../App";
import { BOOK_URL, tele } from "../../../services/constants";
import ModalBooking from "../../ModalBooking";
import ModalBookingSearch from "../../ModalBookingSearch";

function SubmitBooking({
  stepper,
  setStepper,
  bookingDetails,
  setBookingDetails,
  contactDetails,
  setTabOpen,
  setOpenCal,
}) {
  const { language } = useContext(LanguageContext);
  const [payment, setPayment] = useState(1);
  const [loading, setLoading] = useState(false);
  const details = useContext(VendorContext);
  const vendorSlug = useContext(VendorSlugContext);
  const [width, setWidth] = useState(0);
  const { bookingSets } = useContext(BookingSetContext);

  const [trainerNeeded, setTrainerNeeded] = useState(false);

  const [popup, setPopup] = useState(false);

  const resizer = () => {
    setWidth((i) => document.getElementById("slider-width")?.offsetWidth);
  };

  useEffect(() => {
    resizer();
    window.addEventListener("resize", resizer);
    return window.removeEventListener("resize", resizer, true);
  }, []);

  const onSubmit = () => {
    setLoading(() => true);
    let isAddedOnCron =
      bookingSets.cronBooked.length == 0
        ? true
        : !bookingSets.cronBooked.some((cron) => {
          let placeId =
            details?.vendor?.home_page_type != 2 &&
              details?.vendor?.home_page_type != 4
              ? bookingDetails?.branch?.booking_place_id
              : bookingDetails?.branch?.available_place?.[
                bookingDetails?.place
              ]?.booking_place_id;
          let isPlaceSame = placeId == cron.placeId;
          let isDatesSame = cron.dates.includes(
            bookingDetails?.date?.locale("en").format("YYYY-MM-DD")
          );
          let isTimeSame = cron.timeSlots.some((a) => {
            return bookingDetails.time.some(
              (b) =>
                a.slot_start_time == b.slot_start_time &&
                a.slot_end_time == b.slot_end_time
            );
          });

          return isPlaceSame && isDatesSame && isTimeSame;
        });

    if (isAddedOnCron)
      axios
        .post(
          `${BOOK_URL}/save-order-details`,
          JSON.stringify({
            token: process.env.REACT_APP_TOKEN,
            vendor_slug: vendorSlug,
            booking_vendor_id: details?.vendor?.booking_vendor_id,
            booking_place_id:
              details?.vendor?.home_page_type != 2 &&
                details?.vendor?.home_page_type != 4
                ? bookingDetails?.branch?.booking_place_id
                : bookingDetails?.branch?.available_place?.[
                  bookingDetails?.place
                ]?.booking_place_id,
            booking_date: [bookingDetails?.date?.format("YYYY-MM-DD")],
            booking_term: 1,
            first_name: contactDetails?.name,
            email: contactDetails?.email,
            country_code: tele[contactDetails?.phoneCode],
            phone: contactDetails.phone,
            payment_method: payment,
            payment_type: bookingDetails?.branch?.payment_type,
            success_url: window.location.origin + "/",
            error_url: window.location.origin + "/",

            // success_url: window.location.origin + "/beta/",
            // error_url: window.location.origin + "/beta/",

            slot: bookingDetails?.time?.map((t) => ({
              slot_start_time: t?.slot_start_time,
              slot_end_time: t?.slot_end_time,
            })),
            enable_trainer: trainerNeeded ? 1 : 0,
          })
        )
        .then((res) => {
          if (res.data.status)
            window.location.assign(res.data.data.payment_url);
          else {
            setLoading(() => false);
            setPopup(() => true);
          }
        })
        .catch((e) => {
          setLoading(() => false);
          setPopup(() => true);
        });
    else {
      setLoading(() => false);
      setPopup(() => true);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="checkout-step-submit">
          <div className="details-container pt-2">
            <div className="order-details-div pt-4">
              <h3 className="sub-heading pr-3 pl-3">
                {language === "ltr" ? "Booking Details" : "معلومات الحجز"}
              </h3>
              <div className="order-border pt-4 pb-4 pr-3 pl-3">
                <div
                  onClick={() => {
                    deleteB({
                      vendorId: details.vendor.booking_vendor_id,
                      userId: localStorage.getItem("userID"),
                      mainVendor:
                        details?.vendor?.home_page_type != 2 &&
                          details?.vendor?.home_page_type != 4
                          ? bookingDetails?.branch?.booking_vendor_id
                          : bookingDetails?.branch?.available_place?.[
                            bookingDetails?.place
                          ]?.booking_vendor_id,
                    });
                    setStepper(0);
                  }}
                  className="order-timing-tab"
                >
                  <p className="timming-desc flex-home">
                    <span className="del-ic">
                      <i className="fas fa-store-alt"></i>
                    </span>
                    <span className="d-flex flex-column">
                      <span>
                        {language == "ltr"
                          ? details?.vendor?.place_label
                          : details?.vendor?.place_label_ar}
                      </span>
                      <span
                        className="para-text-small-grey pt-1"
                        id="block-addr"
                        style={{ color: "#909090" }}
                      >
                        {language == "ltr"
                          ? bookingDetails?.branch?.name
                          : bookingDetails?.branch?.name_ar}
                      </span>
                    </span>
                  </p>
                  <i
                    className="fa fa-angle-right right-arrow-option"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="order-border pt-4 pb-4 pr-3 pl-3">
                <div
                  onClick={() => {
                    deleteB({
                      vendorId: details.vendor.booking_vendor_id,
                      userId: localStorage.getItem("userID"),
                      mainVendor:
                        details?.vendor?.home_page_type != 2 &&
                          details?.vendor?.home_page_type != 4
                          ? bookingDetails?.branch?.booking_vendor_id
                          : bookingDetails?.branch?.available_place?.[
                            bookingDetails?.place
                          ]?.booking_vendor_id,
                    });
                    setStepper(0);
                  }}
                  className="order-timing-tab"
                >
                  <p className="timming-desc flex-home">
                    <span className="del-ic">
                      <i className="far fa-calendar"></i>
                    </span>
                    <span className="d-flex flex-column">
                      <span>{language == "ltr" ? "Date" : "التاريخ"}</span>
                      <span
                        className="para-text-small-grey pt-1"
                        id="block-addr"
                        style={{ color: "#909090" }}
                      >
                        {language == "ltr" ? (
                          bookingDetails?.date?.format("D MMM YYYY")
                        ) : (
                          <>
                            <span
                              style={{ fontSize: 13 }}
                              className="number-span"
                            >
                              {bookingDetails?.date?.format("D")}
                            </span>{" "}
                            {moment(bookingDetails?.date)
                              ?.locale("ar")
                              ?.format("MMM")}{" "}
                            <span
                              style={{ fontSize: 13 }}
                              className="number-span"
                            >
                              {bookingDetails?.date?.format("YYYY")}
                            </span>
                          </>
                        )}
                      </span>
                    </span>
                  </p>
                  <i
                    className="fa fa-angle-right right-arrow-option"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              {details.vendor.booking_vendor_id !== "24" ? (
                <div className="order-border pt-4 pb-4 pr-3 pl-3">
                  <div
                    onClick={() => {
                      deleteB({
                        vendorId: details.vendor.booking_vendor_id,
                        userId: localStorage.getItem("userID"),
                        mainVendor:
                          details?.vendor?.home_page_type != 2 &&
                            details?.vendor?.home_page_type != 4
                            ? bookingDetails?.branch?.booking_vendor_id
                            : bookingDetails?.branch?.available_place?.[
                              bookingDetails?.place
                            ]?.booking_place_id,
                      });
                      setStepper(0);
                    }}
                    className="order-timing-tab"
                  >
                    <p className="timming-desc flex-home">
                      <span className="del-ic">
                        <i className="far fa-clock"></i>
                      </span>
                      <span className="d-flex flex-column">
                        <span>{language == "ltr" ? "Time" : "الوقت"}</span>
                        <span
                          className="para-text-small-grey pt-1"
                          id="block-addr"
                          style={{ color: "#909090" }}
                        >
                          {bookingDetails?.time?.map((i, k) => (
                            <>
                              {k == 0 ? (
                                ""
                              ) : (
                                <>
                                  , <br></br>
                                </>
                              )}
                              <span
                                style={{ fontSize: 13 }}
                                className="number-span"
                              >
                                {i.start_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.start_time_meridiem
                                : i?.start_time_meridiem_ar}
                              {" - "}
                              <span
                                style={{ fontSize: 13 }}
                                className="number-span"
                              >
                                {i.end_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.end_time_meridiem
                                : i?.end_time_meridiem_ar}
                            </>
                          ))}
                        </span>
                      </span>
                    </p>
                    <i
                      className="fa fa-angle-right right-arrow-option"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              ) : null}

              {details.vendor.booking_vendor_id === "24" ? (
                <div className="order-border pt-4 pb-4 pr-3 pl-3">
                  <div
                    onClick={() => {
                      deleteB({
                        vendorId: details.vendor.booking_vendor_id,
                        userId: localStorage.getItem("userID"),
                        mainVendor:
                          details?.vendor?.home_page_type != 2 &&
                            details?.vendor?.home_page_type != 4
                            ? bookingDetails?.branch?.booking_vendor_id
                            : bookingDetails?.branch?.available_place?.[
                              bookingDetails?.place
                            ]?.booking_place_id,
                      });
                      setStepper(0);
                    }}
                    className="order-timing-tab"
                  >
                    <p className="timming-desc flex-home">
                      <span className="del-ic">
                        <i className="fas fa-users"></i>
                      </span>
                      <span className="d-flex flex-column">
                        {language == "ltr" ? "Party Of" : "عدد الحضور"}
                        <span
                          className="para-text-small-grey pt-1"
                          id="block-addr"
                        >
                          <span
                            style={{ fontSize: 13 }}
                            className="number-span"
                          >
                            {bookingDetails?.attendee}
                          </span>
                        </span>
                      </span>
                    </p>
                    <i
                      className="fa fa-angle-right right-arrow-option"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              ) : null}
              <div className="order-border pt-4 pb-4 pr-3 pl-3">
                <div
                  onClick={() => {
                    setStepper(1);
                  }}
                  className="order-timing-tab"
                >
                  <p className="timming-desc flex-home">
                    <span className="del-ic">
                      <i className="fas fa-user-alt"></i>
                    </span>
                    <span className="d-flex flex-column">
                      <span>{contactDetails?.name}</span>
                      <span
                        className="para-text-small-grey pt-1"
                        id="block-addr"
                        style={{ color: "#909090" }}
                      >
                        <span
                          dir="ltr"
                          style={{ fontSize: 13 }}
                          className="number-span"
                        >
                          +{tele[contactDetails?.phoneCode]}{" "}
                          {contactDetails?.phone}
                        </span>
                      </span>
                    </span>
                  </p>
                  <i
                    className="fa fa-angle-right right-arrow-option"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          {bookingDetails.branch.enable_trainer == 1 ? (
            <div className="details-container pt-2">
              <div className="order-details-div pt-4 pb-4">
                <h3 className="sub-heading pr-3 pl-3">
                  {language == "ltr" ? "Add Trainer" : "أضف المدربين"}
                </h3>
                <div className="order-border mt-3 pr-3 pl-3">
                  <label
                    className="terms"
                    style={{
                      width: "100%",
                      fontSize: language == "ltr" ? 16 : 19,
                    }}
                  >
                    <input
                      onChange={() => {
                        setTrainerNeeded((needed) => !needed);
                      }}
                      type="checkbox"
                      checked={trainerNeeded}
                    ></input>{" "}
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {language == "ltr"
                          ? "I would like to have Personal Trainer."
                          : "أود حجز مدرب شخصي."}
                      </span>
                      {bookingDetails.branch.trainer_cost != 0 ? (
                        <span
                          style={{
                            color: details?.vendor?.vendor_color,
                            fontWeight: 600,
                          }}
                        >
                          &nbsp;
                          <span
                            style={{ fontSize: 16 }}
                            className="number-span"
                          >
                            {parseFloat(
                              bookingDetails.time.length *
                              bookingDetails.branch.trainer_cost
                            ).toFixed(3)}
                          </span>{" "}
                          {language == "ltr" ? "KD" : "د.ك"}
                          &nbsp;
                        </span>
                      ) : null}
                    </p>
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          {bookingDetails?.branch?.payment_type == 2 && (
            <div className="details-container pt-2">
              <div className="order-details-div pt-4 pb-4">
                <h3 className="sub-heading pr-3 pl-3">
                  {language == "ltr" ? "Payment Method" : "طريقة الدفع"}
                </h3>

                <div className="order-border mt-3 pr-3 pl-3">
                  <div className="order-timing-tab">
                    <p
                      className="timming-desc payment-flex"
                      style={{
                        position: "relative",
                      }}
                    >
                      {details?.vendor?.allowed_payment_method == 1 ||
                        details?.vendor?.allowed_payment_method == 3 ? (
                        <Link
                          id="slider-width"
                          onClick={(e) => {
                            e.preventDefault();
                            setPayment(1);
                          }}
                          className={`intro-flex ${payment == 1 ? "active" : ""
                            }`}
                          style={{ width: "100%" }}
                        >
                          <p>
                            <span className="del-ic" style={{ padding: 0 }}>
                              <img
                                style={{ width: 38, height: 28 }}
                                src={"pictures/knet icon mini.png"}
                                className="img-fluid"
                              ></img>
                            </span>
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {payment == 1 ? (
                              <i className="fa fa-check-circle-o"></i>
                            ) : (
                              <i className="fa fa-circle-o"></i>
                            )}
                            &nbsp;
                            {language == "ltr" ? "K-net" : "كي نت"}
                          </span>
                        </Link>
                      ) : null}
                      {details?.vendor?.allowed_payment_method == 2 ||
                        details?.vendor?.allowed_payment_method == 3 ? (
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            setPayment(2);
                          }}
                          className={`intro-flex ${payment == 2 ? "active" : ""
                            }`}
                          style={{ width: "100%" }}
                        >
                          <p>
                            <span className="del-ic" style={{ padding: 0 }}>
                              <img
                                style={{
                                  width: "40px",
                                  height: "30px",
                                  objectFit: "contain",
                                }}
                                src={"pictures/visa.png"}
                                className="img-fluid"
                              ></img>{" "}
                              <img
                                style={{
                                  width: "40px",
                                  height: "30px",
                                  objectFit: "contain",
                                }}
                                src={"pictures/master.png"}
                                className="img-fluid"
                              ></img>
                            </span>
                          </p>
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {payment == 2 ? (
                              <i className="fa fa-check-circle-o"></i>
                            ) : (
                              <i className="fa fa-circle-o"></i>
                            )}
                            &nbsp;
                            {language == "ltr"
                              ? "Credit Card"
                              : "بطاقة الائتمان"}
                          </span>
                        </Link>
                      ) : null}
                      <span
                        className="payment-slider"
                        style={
                          language == "ltr"
                            ? {
                              transform:
                                payment == 1 &&
                                  (details?.vendor?.allowed_payment_method ==
                                    2 ||
                                    details?.vendor?.allowed_payment_method ==
                                    3) &&
                                  details?.vendor?.is_cod == 1
                                  ? `translate(-${width}px)`
                                  : payment == 1 &&
                                    (details?.vendor
                                      ?.allowed_payment_method == 2 ||
                                      details?.vendor
                                        ?.allowed_payment_method == 3 ||
                                      details?.vendor?.is_cod == 1)
                                    ? `translate(-${width / 2}px)`
                                    : payment == 1
                                      ? "translate(0%)"
                                      : payment == 2 &&
                                        details?.vendor?.is_cod == 1
                                        ? "translate(0%)"
                                        : payment == 2
                                          ? `translate(${width / 2}px)`
                                          : payment == 3 &&
                                            (details?.vendor
                                              ?.allowed_payment_method == 2 ||
                                              details?.vendor
                                                ?.allowed_payment_method == 3)
                                            ? `translate(${width}px)`
                                            : payment == 3
                                              ? `translate(${width / 2}px)`
                                              : "",
                            }
                            : {
                              transform:
                                payment == 1 &&
                                  (details?.vendor?.allowed_payment_method ==
                                    2 ||
                                    details?.vendor?.allowed_payment_method ==
                                    3) &&
                                  details?.vendor?.is_cod == 1
                                  ? `translate(${width}px)`
                                  : payment == 1 &&
                                    (details?.vendor
                                      ?.allowed_payment_method == 2 ||
                                      details?.vendor
                                        ?.allowed_payment_method == 3 ||
                                      details?.vendor?.is_cod == 1)
                                    ? `translate(${width / 2}px)`
                                    : payment == 1
                                      ? "translate(0%)"
                                      : payment == 2 &&
                                        details?.vendor?.is_cod == 1
                                        ? "translate(0%)"
                                        : payment == 2
                                          ? `translate(-${width / 2}px)`
                                          : payment == 3 &&
                                            (details?.vendor
                                              ?.allowed_payment_method == 2 ||
                                              details?.vendor
                                                ?.allowed_payment_method == 3)
                                            ? `translate(-${width}px)`
                                            : payment == 3
                                              ? `translate(-${width / 2}px)`
                                              : "",
                            }
                        }
                      ></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="details-container pt-2"
            style={{ marginBottom: "40px" }}
          >
            <div className="order-details-div">
              <div className="cart-pricing-details pr-3 pl-3">
                {bookingDetails?.branch?.payment_type == 2 && (
                  <>
                    <div className="cart-sub-total mt-3">
                      <p className="sub-price">
                        {language == "ltr" ? "Price" : "سعر الحصة الواحدة"}
                      </p>
                      <p className="sub-price">
                        <span>
                          {bookingDetails?.branch?.amount ? parseFloat(bookingDetails?.branch?.amount).toFixed(
                            3
                          ) : 0}
                        </span>{" "}
                        {language === "rtl" ? "د.ك" : "KD"}
                      </p>
                    </div>

                    {details.vendor.booking_vendor_id !== "24" ? (
                      <div className="cart-sub-total mt-3">
                        <p className="sub-price">
                          {language == "ltr" ? "No. of Sessions" : "عدد الحصص"}
                        </p>
                        <p className="sub-price">
                          <span>{bookingDetails?.time?.length}</span>
                        </p>
                      </div>
                    ) : null}

                    {bookingDetails.branch.enable_trainer == 1 &&
                      bookingDetails.branch.trainer_cost != 0 &&
                      trainerNeeded ? (
                      <div className="cart-sub-total mt-3">
                        <p className="sub-price">
                          {language == "ltr" ? "Trainer Cost" : "تكلفة المدرب"}
                        </p>
                        <p className="sub-price">
                          <span>
                            {parseFloat(
                              bookingDetails.time.length *
                              bookingDetails.branch.trainer_cost
                            ).toFixed(3)}
                          </span>{" "}
                          {language === "rtl" ? "د.ك" : "KD"}
                        </p>
                      </div>
                    ) : null}

                    <div className="cart-sub-total mt-3">
                      <p className="sub-heading">
                        {language == "ltr" ? "Total" : "المجموع"}
                      </p>
                      <p className="sub-heading">
                        <span>
                          {(
                            parseFloat(
                              details?.vendor?.home_page_type == 1
                                ? bookingDetails?.time?.length *
                                bookingDetails?.branch?.amount
                                : bookingDetails?.branch?.total_amount
                            ) +
                            (bookingDetails.branch.enable_trainer == 1 &&
                              bookingDetails.branch.trainer_cost != 0 &&
                              trainerNeeded
                              ? parseFloat(
                                bookingDetails.time.length *
                                bookingDetails.branch.trainer_cost
                              )
                              : 0)
                          ).toFixed(3)}
                        </span>{" "}
                        {language === "rtl" ? "د.ك" : "KD"}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bottom-button"
        style={{ position: "absolute", width: "100%" }}
      >
        <Link
          onClick={(e) => {
            e.preventDefault();
            setStepper(stepper - 1);
          }}
          className="text-center checkout-button checkout-button-back"
        >
          {language == "ltr" ? "Back" : "تراجع"}
        </Link>
        <Link
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="text-center checkout-button checkout-button-next"
        >
          {language == "ltr" ? "Reserve" : "إحجز الآن"}
        </Link>
      </div>
      {popup &&
        (details?.vendor?.home_page_type != 2 &&
          details?.vendor?.home_page_type != 3 &&
          details?.vendor?.home_page_type != 4 ? (
          <ModalBooking
            popup={popup}
            setStepper={setStepper}
            setBookingDetails={setBookingDetails}
            setPopup={setPopup}
            bookingDetails={bookingDetails}
            setTabOpen={setTabOpen}
            setOpenCal={setOpenCal}
          ></ModalBooking>
        ) : (
          <ModalBookingSearch
            popup={popup}
            setStepper={setStepper}
            setBookingDetails={setBookingDetails}
            setPopup={setPopup}
            bookingDetails={bookingDetails}
            setTabOpen={setTabOpen}
            setOpenCal={setOpenCal}
          ></ModalBookingSearch>
        ))}
      {loading && (
        <div
          style={{
            background: "rgba(0,0,0,0.2)",
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "60000",
          }}
        >
          <SyncLoader
            color={details?.vendor?.vendor_color}
            loading={loading}
            size={25}
            margin={6}
          ></SyncLoader>
        </div>
      )}
    </>
  );
}

export default SubmitBooking;
