import React, { useContext } from "react";
import { LanguageContext, VendorContext } from "../App";
import $ from "jquery";

function CategorySelect({
  categories,
  setcategoryopen,
  setcategorynow,
  categorynow,
  toggleView,
}) {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  return (
    <div className="category-select-tab">
      <div
        className="category-accordion not-category-accordion"
        onClick={() => {
          setcategoryopen(() => false);
        }}
      >
        {language === "ltr" ? "Categories" : "التصنيفات"}
      </div>
      <div>
        {categories?.map((category, k) => (
          <div
            className={
              categorynow != ""
                ? categorynow == category?.category_name
                  ? "active"
                  : ""
                : "active"
            }
          >
            <div
              onClick={() => {
                setcategorynow(() => category?.category_name);
                setcategoryopen(() => false);
                if (
                  details?.vendor?.home_page_type == 15 ||
                  details?.vendor?.home_page_type == 14
                ) {
                  setTimeout(() => {
                    $("html, body").animate(
                      {
                        scrollTop:
                          $(`#category${k}`).offset().top -
                          (window.screen.width < 991 ? 48 : 0),
                      },
                      "slow"
                    );
                  }, 300);
                } else if (details?.vendor?.home_page_type == 11) {
                  $("#categoryflex").animate(
                    {
                      scrollLeft:
                        $(`#cathort${k}`).position().left +
                        $(`#cathort${k}`).width() / 2 +
                        $("#categoryflex").scrollLeft() -
                        $("#categoryflex").width() / 2,
                    },
                    "slow"
                  );

                  $("html, body").animate(
                    {
                      scrollTop:
                        $(`#category${k}`).offset().top -
                        (window.screen.width < 991 ? 107 : 58),
                    },
                    "slow"
                  );
                } else if (details?.vendor?.home_page_type == 17) {
                  $("#categoryflex").animate(
                    {
                      scrollLeft:
                        $(`#cathort${k}`).position().left +
                        $(`#cathort${k}`).width() / 2 +
                        $("#categoryflex").scrollLeft() -
                        $("#categoryflex").width() / 4,
                    },
                    "2000"
                  );
                  if (toggleView == 1) {
                    $("html, body").animate(
                      {
                        scrollTop:
                          $(`#category${k}`).offset().top -
                          (window.screen.width < 991 ? 107 : 58),
                      },
                      "2000"
                    );
                  } else {
                    $("html, body").animate(
                      {
                        scrollTop:
                          $(`#sidecategory${k}`).offset().top -
                          (window.screen.width < 991 ? 107 : 59),
                      },
                      "2000"
                    );
                  }
                } else {
                  $("html, body").animate(
                    {
                      scrollTop:
                        $(`#category${k}`).offset().top -
                        (window.screen.width < 991 ? 48 : 0),
                    },
                    "slow"
                  );
                }
              }}
              className={`country-list-inner`}
            >
              <span>
                {language === "ltr"
                  ? category?.category_name
                  : category?.category_name_ar}
              </span>
              <span className="product-number">
                {category?.products?.length}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CategorySelect;
